import React, { useCallback } from 'react';

import { QuestApplet } from 'models/QuestApplet';
import {
  AppletPreviewButton,
  AppletPreviewContainer,
  AppletPreviewSelected,
  AppletPreviewSelectedIcon,
  AppletPreviewTitle,
} from '../../components';
import AppletSelectedCheck from 'assets/icons/AppletSelectedCheck.webp';
import useAppletPreviewSwitch from 'hooks/useAppletPreviewSwitch';

type AppletPreviewProps = {
  applet: QuestApplet;
  onAppletClick: (applet: QuestApplet) => void;
  height?: string;
  width?: string;
  margin?: string;
  selected?: boolean;
  showTitle?: boolean;
  backgroundColor?: string;
};

const AppletPreview = ({
  applet,
  onAppletClick,
  height = '139px',
  width = '146px',
  margin = '10px',
  selected = false,
  showTitle = false,
  backgroundColor = 'transparent'
}: AppletPreviewProps) => {
  const renderPreview = useAppletPreviewSwitch(applet);

  const handleClick = useCallback(() => {
    onAppletClick(applet);
  }, [onAppletClick, applet]);

  return (
    <AppletPreviewContainer width={width} margin={margin} >
      <AppletPreviewButton
        type="button"
        onClick={handleClick}
        height={height}
        width={width}
        backgroundColor={backgroundColor}>
        {selected && (
          <AppletPreviewSelected>
            <AppletPreviewSelectedIcon src={AppletSelectedCheck} />
          </AppletPreviewSelected>
        )}
        {renderPreview}
      </AppletPreviewButton>
      {showTitle && <AppletPreviewTitle>{applet?.customTitle ?? applet.title}</AppletPreviewTitle>}
    </AppletPreviewContainer>
  );
};

export default AppletPreview;
