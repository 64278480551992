import { QuestApplet } from 'models/QuestApplet';
import AppletPreview from 'modules/profile/portfolio/quest-applets/AppletPreview';
import { ShowcaseApplet } from 'modules/showcase/typings';
import React from 'react';

import {
  ResumeCardDescription,
  ResumeCardContainer,
  ResumeCardTextContainer,
  ResumeCardText,
} from './ResumeCardComponents';

interface ResumeCardProps {
  applet: ShowcaseApplet | QuestApplet;
  onAppletClick: (applet: QuestApplet) => void;
}

const ResumeCard = ({ applet, onAppletClick }: ResumeCardProps) => {
  return (
    <ResumeCardContainer>
      <AppletPreview
        applet={applet}
        onAppletClick={onAppletClick}
        height="15rem"
        width="15rem"
        margin="0 0 1.77rem 0"
        backgroundColor='#ffffffe6'
      />
      <ResumeCardTextContainer>
        <ResumeCardText>{applet.title}</ResumeCardText>
        <ResumeCardDescription>{applet.description}</ResumeCardDescription>
      </ResumeCardTextContainer>
    </ResumeCardContainer>
  );
};

export default ResumeCard;
