import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ShowcaseBottomItems from '../bottom';
import {
  ShowCaseActionButton,
  ShowcaseContainer,
  ShowCaseImagesContainer,
  ShowcaseItemsContainer,
  ShowCaseTopImage,
  ShowCaseTopImageContainer,
  ShowCaseTopImageOpacity,
} from './ShowcaseResumeComponents';
import { SHOWCASE, SHOWCASE_OVERVIEW } from '../../../routes';
import ResumeCard from './components/ResumeCard';
import { useDispatch, useSelector } from 'react-redux';
import { selectFeaturedApplets } from '../selectors';
import ShowcaseModal from '../overview/components/ShowcaseModal';
import { QuestApplet } from 'models/QuestApplet';
import NavBar from '../navBar';
import { buildQuestAppletsList } from 'helpers/AppletHelpers';
import LoadingScreenSimple from 'modules/loading-screen-simple';
import { getShowcaseGeneral } from '../actions';
import { HeadlineApplet, ShowcaseContent } from '../typings.d';
import useActionStatus from 'hooks/useActionStatus';
import ShowcaseModalHeadline from '../overview/components/ShowcaseModalHeadline';
import ResumeVideo from './components/ResumeVideo';
import GenericErrorScreen from 'modules/generic-error-screen';
import localizedStrings from 'localization/en';

interface ShowCaseResumeProps {
  image?: string;
  video?: string;
}

type ModalState = {
  showcaseOpen?: boolean;
  headlineShowcaseOpen?: boolean;
  applet?: QuestApplet;
  headlineApplets?: HeadlineApplet[];
};

const ShowCaseResume = ({ image, video }: ShowCaseResumeProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [modalState, setModalState] = useState<ModalState>({ showcaseOpen: false });

  const featuredApplets = useSelector(selectFeaturedApplets);
  const groupedFeaturedApplets = useMemo(
    () => buildQuestAppletsList(featuredApplets),
    [featuredApplets],
  );

  const headlineApplets = useMemo(() => {
    return featuredApplets
      .map(el => el.headlineApplet)
      .filter((el): el is HeadlineApplet => el !== undefined);
  }, [featuredApplets]);

  const [isLoading, wasFullfilled, wasRejected] = useActionStatus(getShowcaseGeneral);

  const openModalHeadline = useCallback(
    (applet: QuestApplet) => {
      (headlineApplets && applet) && setModalState({ headlineShowcaseOpen: true, headlineApplets, applet });
    },
    [headlineApplets],
  )

  const openModal = (applet: QuestApplet) => {
    setModalState({ showcaseOpen: true, applet });
  };

  const closeModal = () => {
    setModalState({ showcaseOpen: false });
  };

  const goToOverview = useCallback(
    () => history.push(`${SHOWCASE}/${id + SHOWCASE_OVERVIEW}`),
    [history, id],
  );

  const aboveFoldHero = useMemo(
    () =>
      image ? (
        <ShowCaseTopImageContainer>
          <ShowCaseTopImage src={image} />
          <ShowCaseTopImageOpacity />
        </ShowCaseTopImageContainer>
      ) : (
        <ResumeVideo videoSource={video ? video : ''} />
      ),
    [image, video],
  );

  useEffect(() => {
    if (id && id !== '' && !wasFullfilled && !wasRejected) {
      dispatch(getShowcaseGeneral({ showcaseId: id, typeContent: ShowcaseContent.GENERAL }));
    }
  }, [dispatch, id, wasFullfilled, wasRejected]);

  if (wasRejected) {
    return <GenericErrorScreen customMsg={localizedStrings.errorShowcase} />
  }

  if (isLoading || !wasFullfilled) {
    return <LoadingScreenSimple />
  }

  return (
    <ShowcaseContainer>
      <NavBar />
      {aboveFoldHero}
      <ShowcaseItemsContainer>
        <ShowcaseModal
          open={modalState.showcaseOpen ?? false}
          applet={modalState.applet}
          closeModal={closeModal} />
        {(headlineApplets.length) ?
          <ShowcaseModalHeadline
            open={modalState.headlineShowcaseOpen ?? false}
            applet={modalState.applet}
            headlineApplets={headlineApplets}
            closeModal={closeModal}
          /> : null}

        <ShowCaseImagesContainer>
          {groupedFeaturedApplets.map((applet) => (
            <ResumeCard
              applet={applet}
              onAppletClick={(headlineApplets.length) ? openModalHeadline : openModal}
            />
          ))}
        </ShowCaseImagesContainer>
        <ShowCaseActionButton onClick={goToOverview}>
          Learn more
        </ShowCaseActionButton>
      </ShowcaseItemsContainer>
      <ShowcaseBottomItems />
    </ShowcaseContainer>
  );
};

export default ShowCaseResume;
