import React from 'react';
import logo from 'assets/images/Logo.webp';
import { BgContainer, TxtMessage } from 'common/CommonComponents';
import BackendDownError from 'components/backend-down-error';

const GenericErrorScreen = ({ customMsg }: { customMsg?: string }) => {
  return (
    <BgContainer>
      <img src={logo} alt="brand logo" />
      <BackendDownError />
      {customMsg && <TxtMessage>{customMsg}</TxtMessage>}
    </BgContainer>
  );
};

export default GenericErrorScreen;
