import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/typings';

import { getQuestApplets } from 'modules/applets/actions';
import { getActiveQuest, resetCompleteQuestAdded, setLoadingAdditionalFinished, setLoadingHomeFinished } from 'modules/loading-screen/quests/actions';
import { selectWeek } from 'modules/loading-screen/weeks/actions';
import { getWeekOnboardingSeen } from 'modules/onboarding/actions';
import {
  selectActiveQuest,
  selectActiveQuestWeekIds,
  selectCompleteQuestAdded,
  selectLoadingLastContentFinished,
} from 'modules/loading-screen/quests/selectors';

import useActionStatus from '../useActionStatus';

import { selectLoadingScreenErrors } from 'modules/error/selectors';
import { useHistory } from 'react-router';
import { GENERIC_ERROR_SCREEN } from 'routes';
import { getAchievements } from 'modules/profile/actions';
import useLoadContentfulLastContent from 'hooks/useLoadContentfulLastContent';
import { getTags } from 'modules/tags/actions';


type useLoadContentReturn = {
  loadFinished: boolean;
};

const useLoadLastContent = (): useLoadContentReturn => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [hasLoadedLastContent] = useLoadContentfulLastContent();

  const activeQuest = useSelector(selectActiveQuest);
  const activeQuestWeekIds = useSelector(selectActiveQuestWeekIds);
  const loadingHomeFinished = useSelector(selectLoadingLastContentFinished);

  const [, getQuestAppletsWasFulfilled] = useActionStatus(getQuestApplets);
  const [, getWeekOnboardingSeenFulfilled] = useActionStatus(getWeekOnboardingSeen);
  const [, getAchievementsWasFulfilled] = useActionStatus(getAchievements);
  const [, getTagsWasFullfilled] = useActionStatus(getTags);

  const loadingScreenErrors = useSelector(selectLoadingScreenErrors);
  const completeQuestAdded = useSelector(selectCompleteQuestAdded);

  const loadFinished = useMemo(() => {
    const loadFinished =
      getQuestAppletsWasFulfilled &&
      getWeekOnboardingSeenFulfilled &&
      getAchievementsWasFulfilled &&
      hasLoadedLastContent &&
      getTagsWasFullfilled;

    if (loadFinished) {
      dispatch(setLoadingHomeFinished(loadFinished));
      dispatch(setLoadingAdditionalFinished(loadFinished));
    }
    return loadFinished;
  }, [
    getQuestAppletsWasFulfilled,
    getAchievementsWasFulfilled,
    getWeekOnboardingSeenFulfilled,
    hasLoadedLastContent,
    getTagsWasFullfilled,
    dispatch,
  ]);

  useEffect(() => {
    if (!completeQuestAdded) {
      dispatch(resetCompleteQuestAdded());
      window.location.reload();
    }
  }, [completeQuestAdded, dispatch])


  useEffect(() => {
    if (!activeQuest) {
      dispatch(getActiveQuest());
    }

    if (!loadingHomeFinished) {
      dispatch(getAchievements());
      dispatch(getTags());
    }

    if (!loadingHomeFinished && activeQuest) {
      dispatch(getQuestApplets());

      const lastWeekId = activeQuestWeekIds[activeQuestWeekIds.length - 1];
      dispatch(selectWeek(lastWeekId));
      activeQuestWeekIds.forEach(weekId => {
        dispatch(getWeekOnboardingSeen({ weekId }));
      });
    }
  }, [dispatch, loadingHomeFinished, activeQuest, activeQuestWeekIds]);


  useEffect(() => {
    if (loadingScreenErrors && loadingScreenErrors.length > 0) {
      history.push(GENERIC_ERROR_SCREEN);
    }
  }, [loadingScreenErrors, history]);

  return { loadFinished };
};

export default useLoadLastContent;
