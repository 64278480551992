import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import localizedStrings from 'localization';
import {
  FieldErrorMessage,
  PublishShowcaseAppletsContainer,
  PublishShowcaseDescription,
  PublishShowcaseSelectAll,
} from 'modules/profile/components';
import { selectQuestPortfolioApplets } from 'modules/applets/selectors';
import AppletPreview from 'modules/profile/portfolio/quest-applets/AppletPreview';
import { SetFieldValueType, StepPropsSplit } from './typings';
import { buildQuestAppletsList } from 'helpers/AppletHelpers';
import useHandleAppletSelect from 'hooks/useHandleAppletSelect';
import useIsAppletSelected from 'hooks/useIsAppletSelected';
import { ShowMoreShowcaseItem } from '../ShowMoreShowcaseItem';

const SelectArtifacts = ({
  values,
  setFieldValue,
}: StepPropsSplit) => {
  const [showMore, setShowMore] = useState(false);
  const questPortfolioApplets = useSelector(selectQuestPortfolioApplets);

  const handleShowMore = () => setShowMore(!showMore);

  const applets = useMemo(
    () =>
      buildQuestAppletsList(questPortfolioApplets),
    [questPortfolioApplets],
  );

  const selectAll = useCallback(
    (setFieldValue: SetFieldValueType) => {
      const allIds = applets
        .map((app) =>
          app.appletType === 'group-applet'
            ? app.applets?.map((groupApp) => groupApp.id)
            : app.id,
        )
        .flat();
      setFieldValue('applets', allIds);
    },
    [applets],
  );

  const hasSelectedAll = useCallback(
    () =>
      values?.applets?.length ===
      applets
        .map((app) =>
          app.appletType === 'group-applet'
            ? app.applets?.map((groupApp) => groupApp.id)
            : app.id,
        )
        .flat().length,
    [applets, values?.applets],
  );

  const deselectAll = useCallback((setFieldValue: SetFieldValueType) => {
    setFieldValue('applets', []);
  }, []);

  const handleSelectDeselect = useCallback(() => {
    if (hasSelectedAll()) {
      deselectAll(setFieldValue);
    } else {
      selectAll(setFieldValue);
    }
  }, [deselectAll, hasSelectedAll, selectAll, setFieldValue]);

  const handleAppletSelect = useHandleAppletSelect(
    setFieldValue,
    values?.applets || [],
    'applets',
  );

  const isSelected = useIsAppletSelected(values?.applets || []);


  return !showMore ?
    <ShowMoreShowcaseItem
      title={localizedStrings.artifactsIncluded}
      action={handleShowMore}
      actionState={showMore}
      labelShow={localizedStrings.change}
    />
    : (
      <>
        <ShowMoreShowcaseItem title={localizedStrings.artifactsIncluded} />
        <PublishShowcaseDescription>
          {localizedStrings.selectShowcaseArtifacts}
          {values?.applets && values?.applets?.length < 3 ? (
            <FieldErrorMessage>
              {localizedStrings.showcaseArtifactsMinimumError}
            </FieldErrorMessage>
          ) : null}
        </PublishShowcaseDescription>

        <>
          <PublishShowcaseSelectAll
            type="button"
            onClick={handleSelectDeselect}>
            {hasSelectedAll()
              ? localizedStrings.deselectAll
              : localizedStrings.selectAll}
          </PublishShowcaseSelectAll>
          <PublishShowcaseAppletsContainer>
            {
              applets.map((applet) => (
                <AppletPreview
                  key={applet.id}
                  applet={applet}
                  onAppletClick={handleAppletSelect}
                  showTitle={true}
                  height="168px"
                  width="178px"
                  selected={isSelected(applet)}
                />
              ))
            }
          </PublishShowcaseAppletsContainer>
        </>

      </>
    );
};

export default SelectArtifacts;
