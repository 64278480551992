import React, { useCallback, useMemo } from 'react';

import { QuestApplet } from 'models/QuestApplet';
import {
  ShowcaseModalBackground,
  ShowcasePictureModalPanel,
  ShowcaseModalCloseButton,
  ShowcaseModalHeaderVideo,
  ShowcasePictureCard,
  ShowCaseSeeMoreText,
  ShowcasePictureModalContent,
} from '../ShowcaseOverviewComponents';
import Close from 'assets/icons/Close.webp';
import AppletPreviewSwitch from '../../../../common/applet-preview-switch/index';

type ShowcaseModalProps = {
  open: boolean;
  applet?: QuestApplet;
  closeModal: () => void;
};

const ShowcaseModal = ({
  open,
  applet,
  closeModal,
}: ShowcaseModalProps) => {
  const renderPreview = useMemo(() => {
    if (applet) {
      return <AppletPreviewSwitch applet={applet} />;
    }
  }, [applet]);

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <ShowcaseModalBackground visible={open}>
      <ShowcasePictureModalPanel>
        <ShowcaseModalCloseButton onClick={handleClose}>
          <img src={Close} alt="close" />
        </ShowcaseModalCloseButton>
        <ShowcaseModalHeaderVideo>
          {applet?.title}
        </ShowcaseModalHeaderVideo>
        <ShowCaseSeeMoreText>
          {applet?.description}
        </ShowCaseSeeMoreText>
        <ShowcasePictureModalContent>
          <ShowcasePictureCard>{renderPreview}</ShowcasePictureCard>
        </ShowcasePictureModalContent>
      </ShowcasePictureModalPanel>
    </ShowcaseModalBackground>
  );
};

export default ShowcaseModal;
