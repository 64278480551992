import React from 'react';
import localizedStrings from 'localization';
import {
  ContainerGeneralInfo,
  GeneralInfoFlex,
  PublishShowcaseGeneralLabels,
  ShowcaseInput,
} from 'modules/profile/components';
import { StepPropsSplit } from './typings';
import { FieldErrorMessage } from 'components/input-field/components';

const GeneralInfo = (props: StepPropsSplit) => {
  return (
    <ContainerGeneralInfo>
      <GeneralInfoFlex>
        <PublishShowcaseGeneralLabels>
          {localizedStrings.selectShowcaseCallToAction}
        </PublishShowcaseGeneralLabels>
        <ShowcaseInput name="callToAction" defaultValue={props.values.callToAction} />
        {!props.values.callToAction ? (
          <FieldErrorMessage>
            {localizedStrings.requiredField}
          </FieldErrorMessage>
        ) : null}
      </GeneralInfoFlex>

      <GeneralInfoFlex>
        <PublishShowcaseGeneralLabels>
          {localizedStrings.selectShowcaseName}
        </PublishShowcaseGeneralLabels>
        <ShowcaseInput name="name" defaultValue={props.values.name} />
        {!props.values.name ? (
          <FieldErrorMessage>
            {localizedStrings.requiredField}
          </FieldErrorMessage>
        ) : null}
      </GeneralInfoFlex>
    </ContainerGeneralInfo>
  );
};

export default GeneralInfo;
