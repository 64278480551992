import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import localizedStrings from 'localization';
import {
  FieldErrorMessage,
  PublishShowcaseAppletsContainer,
  PublishShowcaseDescription,
  ShowcaseBackgroundImg,
} from 'modules/profile/components';
import { selectAppletsImageToAddShowcase } from 'modules/applets/selectors';
import { QuestApplet } from 'models/QuestApplet';
import AppletPreview from 'modules/profile/portfolio/quest-applets/AppletPreview';
import { StepPropsSplit } from './typings';
import { ShowMoreShowcaseItem } from '../ShowMoreShowcaseItem';

const SelectBackgroundImage = ({
  values,
  setFieldValue,
  backgroundUrl
}: StepPropsSplit & { backgroundUrl?: string }) => {
  const applets = useSelector(selectAppletsImageToAddShowcase);
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => setShowMore(!showMore);

  const handleAppletSelect = useCallback(
    (applet: QuestApplet) => {
      setFieldValue('backgroundApplet', applet.id);
    },
    [setFieldValue],
  );

  return !showMore ?
    <>
      <ShowMoreShowcaseItem
        title={localizedStrings.showcaseBackgundImage}
        action={handleShowMore}
        actionState={showMore}
        labelShow={localizedStrings.change}
      />
      <ShowcaseBackgroundImg src={backgroundUrl} />
    </>
    :
    (
      <>
        <ShowMoreShowcaseItem title={localizedStrings.showcaseBackgundImage} />
        <PublishShowcaseDescription>

          {!applets?.length ? (
            <FieldErrorMessage>
              {localizedStrings.showcaseBackgroundMinimumError}
            </FieldErrorMessage>
          ) : localizedStrings.selectShowcaseBackground}
        </PublishShowcaseDescription>

        <PublishShowcaseAppletsContainer>
          {applets.map((applet) => (
            <AppletPreview
              key={applet.id}
              applet={applet}
              onAppletClick={handleAppletSelect}
              showTitle={true}
              height="168px"
              width="178px"
              selected={values.backgroundApplet === applet.id}
            />
          ))}
        </PublishShowcaseAppletsContainer>
      </>
    );
};

export default SelectBackgroundImage;
