import { createReducer } from '@reduxjs/toolkit';

import {
  createShowcase,
  deleteShowcase,
  getAchievements,
  getGenericApplets,
  getPublishedShowcases,
  publishShowcase,
  updateProfileMenuSelected,
  updateSortByQuest,
} from './actions';
import { ProfileState } from './typings';

const initialState: ProfileState = {
  achievementsState: {
    totalBp: 0,
    totalXp: 0,
    achievements: [],
  },
  showcaseState: { genericApplets: [] },
  profileMenuSelected: 'NotebookGrid',
  sortByQuest: true,
};

export default createReducer<ProfileState>(initialState, (builder) =>
  builder
    .addCase(getAchievements.fulfilled, (state, action) => ({
      ...state,
      achievementsState: action.payload,
    }))
    .addCase(getPublishedShowcases.fulfilled, (state, action) => ({
      ...state,
      showcaseState: {
        ...state.showcaseState,
        publishedShowcases: action.payload,
      },
    }))
    .addCase(getGenericApplets.fulfilled, (state, action) => ({
      ...state,
      showcaseState: {
        ...state.showcaseState,
        genericApplets: action.payload,
      },
    }))
    .addCase(publishShowcase.fulfilled, (state, action) => ({
      ...state,
      showcaseState: {
        ...state.showcaseState,
        publishedShowcases: [
          ...(state.showcaseState.publishedShowcases ?? []),
          action.payload,
        ],
      },
    }))
    .addCase(createShowcase.fulfilled, (state, action) => ({
      ...state,
      showcaseState: {
        ...state.showcaseState,
        publishedShowcases: [
          ...(state.showcaseState.publishedShowcases ?? []),
          action.payload,
        ],
      },
    }))
    .addCase(deleteShowcase.fulfilled, (state, action) => ({
      ...state,
      showcaseState: {
        ...state.showcaseState,
        publishedShowcases: state.showcaseState.publishedShowcases?.filter(
          (showcase) => showcase.id !== action.meta.arg,
        ),
      },
    }))
    .addCase(updateProfileMenuSelected, (state, action) => ({
      ...state,
      profileMenuSelected: action.payload,
    }))
    .addCase(updateSortByQuest, (state, action) => ({
      ...state,
      sortByQuest: action.payload,
    })),
);
