import styled from '@emotion/styled';

export const NavBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${(props) => props?.theme?.colors.blue3[100]};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const NavBarContact = styled.button`
  background-color: transparent;
  padding: 0;
  border: none;
  transition: filter 0.5s ease-in-out;

  &:hover {
    filter: drop-shadow(0 0 0px rgba(244, 191, 93, 0.7)) 
            drop-shadow(0 0 2px rgba(244, 191, 93, 0.5)) 
            drop-shadow(0 0 4px rgba(244, 191, 93, 0.3));
  }
`;

export const NavBarContactImage = styled.img`
  height: 1.7rem;
  width: 5.3rem;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    height: 2.2rem;
    width: 6.3rem;
  }
`;

export const NavBarLeftItems = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props) => props?.theme?.colors.blue3[100]};
  min-height: 4.5rem;
  padding: 0 0.8rem;
`;

export const NavBarLine = styled.img`
  height: 2.4rem;
  margin-left: 0.6rem;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    height: 3.2rem;
    margin-left: 1.6rem;
  }
`;

export const NavBarLogo = styled.img`
  height: 2rem;
  margin-left: 0;
  cursor:pointer;
  transition: filter 0.5s ease-in-out;

  &:hover {
    filter: drop-shadow(0 0 0px rgba(244, 191, 93, 0.7)) 
            drop-shadow(0 0 2px rgba(244, 191, 93, 0.5)) 
            drop-shadow(0 0 4px rgba(244, 191, 93, 0.3));
  }

  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    height: 2.77rem;
    margin-left: 3.16rem;
  }
`;

export const NavBarName = styled.p`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  margin-right: 1.38rem;
  margin-bottom: 0;
  margin-left: 0.67rem;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    font-size: 1.3rem;
    margin-left: 1.67rem;
  }
`;
