import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Logo_yellow from 'assets/images/Logo_yellow.webp';
import Line from 'assets/images/Line.webp';
import Contact from 'assets/images/Contact.webp';
import {
  NavBarContainer,
  NavBarContact,
  NavBarContactImage,
  NavBarLeftItems,
  NavBarLine,
  NavBarLogo,
  NavBarName,
} from './NavBarComponents';
import { SHOWCASE, SHOWCASE_CONTACT, SHOWCASE_RESUME } from 'routes';
import { useSelector } from 'react-redux';
import { selectUserDisplayName } from 'modules/showcase/selectors';

const NavBar = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const userDisplayName = useSelector(selectUserDisplayName);

  const handleGoToResume = () => history.push(`${SHOWCASE}/${id + SHOWCASE_RESUME}`)
  const handleGoToContact = () => history.push(`${SHOWCASE}/${id + SHOWCASE_CONTACT}`)

  return (
    <NavBarContainer>
      <NavBarLeftItems>
        <NavBarLogo onClick={handleGoToResume} src={Logo_yellow} alt="mynga" />
        <NavBarLine src={Line} />
        <NavBarName>{userDisplayName}</NavBarName>
        <NavBarContact onClick={handleGoToContact}>
          <NavBarContactImage src={Contact} />
        </NavBarContact>
      </NavBarLeftItems>
    </NavBarContainer>
  );
};

export default NavBar;
