import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getShowcaseCreationDate as getShowcaseCreationDateService,
  getShowcase as getShowcaseService,
  sendEmail as sendEmailService,
} from './services';
import { Showcase, ShowcaseEmail } from './typings';

const GET_SHOWCASE_CREATION_DATE = 'GET_SHOWCASE_CREATION_DATE';
export const getShowcaseCreationDate = createAsyncThunk<Date, { showcaseId: string }>(
  GET_SHOWCASE_CREATION_DATE,
  getShowcaseCreationDateService,
);


const GET_SHOWCASE_GENERAL = 'GET_SHOWCASE_GENERAL';
export const getShowcaseGeneral = createAsyncThunk<Partial<Showcase>, { showcaseId: string, typeContent: string }>(
  GET_SHOWCASE_GENERAL,
  getShowcaseService,
);

const GET_SHOWCASE_ARTIFACT = 'GET_SHOWCASE_ARTIFACT';
export const getShowcaseArtifact = createAsyncThunk<Partial<Showcase>, { showcaseId: string, typeContent: string }>(
  GET_SHOWCASE_ARTIFACT,
  getShowcaseService,
);

const SEND_EMAIL = 'SEND_EMAIL';
export const sendEmail = createAsyncThunk<void, ShowcaseEmail>(
  SEND_EMAIL,
  sendEmailService,
);

const CLEAR_SHOWCASE = 'CLEAR_SHOWCASE'
export const clearShowcase = createAction(CLEAR_SHOWCASE)