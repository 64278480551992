import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

import { appletToQuestApplet } from 'helpers/AppletHelpers';
import { QuizQuestion } from 'models/Applet';
import { QuestApplet } from 'models/QuestApplet';
import { selectAppletsContentful } from 'modules/loading-screen/contentful/applets/selectors';
import { QuestPortfolio } from 'models/QuestPortfolio';
import { IMAGE_MIMETYPE, VIDEO_MIMETYPE } from 'helpers/FileHelpers';

const selectQuestApplets = (state: RootState) => state.applets;

export const selectAvailableApplets = createSelector(
  selectQuestApplets,
  (questApplets) => questApplets.list,
);

export const selectAppletsFilledStateSelector = createSelector(
  selectQuestApplets,
  (questApplets) => ({
    appletsActiveQuestFilled: questApplets.appletsActiveQuestFilled,
    appletsRestQuestFilled: questApplets.appletsRestQuestFilled,
  }),
);

export const selectSelectedAppletIdx = createSelector(
  selectQuestApplets,
  (questApplets) => questApplets.selectedIdx,
);

export const selectSelectedApplet = createSelector(
  selectAvailableApplets,
  selectSelectedAppletIdx,
  (appletsList, appletIdx) =>
    appletIdx !== undefined ? appletsList[appletIdx] : undefined,
);

export const selectQuestQuizQuestion = (
  quizContentfulId: string,
  quizQuestionContentfulId: string,
) =>
  createSelector(selectAvailableApplets, (questPortfolios) => {
    let quizQuestion: QuizQuestion | undefined;
    for (const quest of questPortfolios) {
      const questApplet = quest.applets.find(
        (app) => app.contentfulId === quizContentfulId,
      );
      quizQuestion = questApplet?.quizQuestions?.find(
        (question) =>
          question.quizQuestionContentfulId === quizQuestionContentfulId,
      );
      if (quizQuestion) {
        break;
      }
    }

    return quizQuestion;
  });

// active quest

export const selectActiveQuestPortfolioApplets = createSelector(
  selectQuestApplets,
  selectAppletsContentful,
  (questApplets, appletsContentful) => {
    const applets = questApplets.list.filter(quest => quest.isActiveQuest).flatMap((portfolio) => portfolio.applets);
    return applets.map((applet) => appletToQuestApplet(applet, appletsContentful[applet.contentfulId]));
  },
);

export const hasMinimunAppletsCompletedForShowcase = createSelector(
  selectActiveQuestPortfolioApplets,
  (applets) => Boolean(applets.length >= 2))


// all quests

export const selectQuestPortfolioApplets = createSelector(
  selectQuestApplets,
  selectAppletsContentful,
  (questApplets, appletsContentful) => {
    const applets = questApplets.list.flatMap((portfolio) => portfolio.applets);
    return applets.map((applet) => appletToQuestApplet(applet, appletsContentful[applet.contentfulId]));
  },
);

export const selectAppletsImageToAddShowcase = createSelector(
  selectQuestPortfolioApplets,
  (questPortfolioApplets) =>
    questPortfolioApplets.filter(
      (applet) =>
        applet.appletType === 'upload-image'
        || (applet.appletType === 'upload-file' && applet.fileType === IMAGE_MIMETYPE)
        || (applet.appletType === 'generic-upload-file' && applet.fileType === IMAGE_MIMETYPE)))

export const selectExistsAppletImagesToAddShowcase = createSelector(
  selectAppletsImageToAddShowcase,
  (applets) => Boolean(applets.length))



export const selectAppletsVideoToAddShowcase = createSelector(
  selectQuestPortfolioApplets,
  (questPortfolioApplets) =>
    questPortfolioApplets.filter(
      (applet) =>
        applet.appletType === 'upload-video'
        || (applet.appletType === 'upload-file' && applet.fileType === VIDEO_MIMETYPE)
        || (applet.appletType === 'generic-upload-file' && applet.fileType === VIDEO_MIMETYPE)))

export const selectExistsAppletVideosToAddShowcase = createSelector(
  selectAppletsVideoToAddShowcase,
  (applets) => Boolean(applets.length))



export const selectAllAppletsMap = createSelector(
  selectQuestPortfolioApplets,
  (applets) =>
    applets.reduce(
      (acc, app) => ({
        ...acc,
        [app.contentfulId]: app,
      }),
      {} as Record<string, QuestApplet>,
    ),
);

export const selectQuestChallengeApplet = (appletId: string) =>
  createSelector(selectAllAppletsMap, (appletsMap) => appletsMap[appletId]);

export const selectAvailableAppletsLength = createSelector(
  selectAvailableApplets,
  (questApplets) =>
    questApplets.flatMap(
      (questPortfolioApplet: QuestPortfolio) => questPortfolioApplet.applets,
    ).length || 0,
);

export const selectAppletsFromAllUserQuests = createSelector(
  selectAvailableApplets,
  (applets) => applets.flatMap((quest) => quest.applets)
);


export const selectLastAppletModifiedById = (appletId: string) =>
  createSelector(
    selectAppletsFromAllUserQuests,
    (applets) => {
      const filteredApplets = applets.filter((applet) => applet.contentfulId === appletId);
      const sortedApplets = filteredApplets.sort((a, b) => {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateA + dateB;
      });

      return sortedApplets[0];
    }
  );
