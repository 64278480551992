import React, { useCallback } from 'react';

import { QuestApplet } from 'models/QuestApplet';
import {
  ShowcaseModalBackground,
  ShowcasePictureModalPanel,
  ShowcaseModalCloseButton,
  ShowcasePictureCard,
  ShowcasePictureModalContent,
  ShowcaseModalHeaderVideo,
  ShowCaseSeeMoreText,
} from '../ShowcaseOverviewComponents';
import Close from 'assets/icons/Close.webp';
import ShowcaseHeadline from 'modules/showcase/home/ShowcaseHeadline';
import { HeadlineApplet } from 'modules/showcase/typings';

type ShowcaseModalProps = {
  open: boolean;
  applet?: QuestApplet;
  headlineApplets: HeadlineApplet[];
  closeModal: () => void;
};

const ShowcaseModalHeadline = ({
  open,
  applet,
  headlineApplets,
  closeModal,
}: ShowcaseModalProps) => {

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <ShowcaseModalBackground visible={open}>
      <ShowcasePictureModalPanel>
        <ShowcaseModalCloseButton onClick={handleClose}>
          <img src={Close} alt="close" />
        </ShowcaseModalCloseButton>
        <ShowcaseModalHeaderVideo>
          {applet?.title}
        </ShowcaseModalHeaderVideo>
        <ShowCaseSeeMoreText>
          {applet?.description}
        </ShowCaseSeeMoreText>
        <ShowcasePictureModalContent>
          <ShowcasePictureCard>
            <ShowcaseHeadline headlineApplets={headlineApplets} />
          </ShowcasePictureCard>
        </ShowcasePictureModalContent>
      </ShowcasePictureModalPanel>
    </ShowcaseModalBackground>
  );
};

export default ShowcaseModalHeadline;
