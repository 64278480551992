import { Document } from '@contentful/rich-text-types';
import { Applet, AppletType } from 'models/Applet';
import { ChallengeApplet } from 'models/ChallengeApplet';
import { Feedback } from 'models/Feedback';
import { Post } from 'models/Post';
import { QuestApplet } from 'models/QuestApplet';
import { VisibilityAchievementsType } from 'modules/auth/typings';

export enum ACHIEVEMENT_TYPE {
  CHALLENGE = 'CHALLENGE',
  REVIEW = 'REVIEW',
  WEEK_PROMISE = 'WEEK_PROMISE',
  QUEST_PROMISE = 'QUEST_PROMISE',
}
export interface Submission {
  applets?: ChallengeApplet[];
  postUser?: Post;
}
export interface Achievement {
  id: string;
  type: ACHIEVEMENT_TYPE;
  xp: number;
  bp: number;
  questContentfulId?: string;
  weekContentfulId?: string;
  contentContentfulId?: string;
  postUserId?: string;
  feedbacks?: Feedback[];
  appletIds?: string[];
  applets?: Applet[];
  reflectAnswers?: string;
  fileType?: string;
  fileUrl?: string;
}
export interface AchievementsState {
  totalXp: number;
  totalBp: number;
  achievements: Achievement[];
  activeQuestContentfulId?: string;
  visibilityPermissions?: VisibilityAchievementsType;
}

export type ShowcaseState = {
  publishedShowcases?: ShowcaseItem[];
  genericApplets: GenericApplet[];
};

export type ProfileState = {
  achievementsState: AchievementsState;
  showcaseState: ShowcaseState;
  profileMenuSelected:
  | 'ManageQuests'
  | 'AchievementsList'
  | 'NotebookGrid'
  | 'ShowcasesList';
  sortByQuest: boolean;
};

export interface ShowcaseItem {
  id: string;
  title: string;
  link: string;
  createdAt: Date;
}

export interface PublishShowcaseArgs {
  title: string;
  callToAction: string;
  appletIds: string[];
  featuredAppletIds: string[];
  headlineAppletIds?: string[];
  backgroundUrl: string;
  videoUrl: string;
}

export interface UpdateShowcaseBodyArgs {
  title: string;
  callToAction: string;
  appletIds: string[];
  featuredAppletIds: string[];
  headlineAppletIds?: string[];
  backgroundUrl?: string;
  videoUrl?: string;
}

export interface ShareShowcaseArgs {
  showcaseId: string;
  data: {
    email: string;
    name: string;
    message: string;
  };
}

export interface UpdateShowcaseArgs {
  showcaseId: string;
  data: {
    title?: string;
    callToAction?: string;
    appletIds?: string[];
    featuredAppletIds?: string[];
    headlineAppletIds?: string[];
    backgroundUrl?: string;
    videoUrl?: string;
  };
}

export interface GenericApplet {
  id: string;
  type: Extract<AppletType, 'generic-rich-text' | 'generic-upload-file'>;
  uploadPrompt: Document;
  defaultHtml?: string;
}

export type AppletsTitle = {
  title: string;
  applets: QuestApplet[];
};

export interface ShowcaseCreateArgs {
  activeQuestContentfulId: string;
  activeQuestId: string;
}
