import logo from 'assets/images/Logo.webp';
import localizedStrings from 'localization';

import { BgContainer, TxtMessage } from 'common/CommonComponents';
import { LoadingSquare } from 'components/loading-square/LoadingSquare';

const LoadingScreenSimple = () => {
  return (
    <BgContainer>
      <img src={logo} alt="brand logo" />
      <TxtMessage>{localizedStrings.loading}</TxtMessage>
      <LoadingSquare />
    </BgContainer>
  );
};

export default LoadingScreenSimple;
