import React from 'react'
import localizedStrings from 'localization/en'
import { ActionLinkShowcase, PublishShowcaseSubtitle, ShowcaseRow } from 'modules/profile/components'

type ShowMoreShowcaseItemType = {
    title: string,
    action?: () => void,
    actionState?: boolean,
    labelShow?: string,
    labelHide?: string
    hideAction?: boolean,
}

export const ShowMoreShowcaseItem = ({ title, action, actionState, labelShow, labelHide }: ShowMoreShowcaseItemType) => {
    const show = labelShow || localizedStrings.showMore
    const hide = labelHide || localizedStrings.showLess
    return (
        <ShowcaseRow>
            <PublishShowcaseSubtitle>
                {title}
            </PublishShowcaseSubtitle>
            {action &&
                <ActionLinkShowcase onClick={action}>
                    {!actionState ? show : hide}
                </ActionLinkShowcase>
            }
        </ShowcaseRow>
    )
}
