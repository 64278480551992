import React, { Suspense, useEffect, useMemo } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import {
  SHOWCASE_CONTACT,
  SHOWCASE,
  SHOWCASE_RESUME,
  SHOWCASE_OVERVIEW,
  SHOWCASE_PARAMS,
  SHOWCASE_RESUME_VIDEO,
} from 'routes';
import ShowcaseContact from './contact';
import ShowcaseOverview from './overview';
import ShowCaseResume from './resume';
import { useDispatch, useSelector } from 'react-redux';
import { selectBackgroundUrl, selectShowcaseCreatedAt, selectVideoUrl } from './selectors';

import showcaseWhoami from 'assets/images/showcase-whoami.jpg';
import showcaseDeclaration from 'assets/images/showcase-declaration.jpg';
import showcaseParenting1 from 'assets/images/showcase-parenting-1.jpg';
import showcaseChooseTodoSomethingHard from 'assets/images/showcase-choose-todo-something-hard.jpg';
import showcaseEntrepreneurship from 'assets/images/showcase-entrepreneurship.jpg';
import showcaseParenting2 from 'assets/images/showcase-parenting-2.jpg';

import ShowcaseHome from './home';
import { getShowcaseCreationDate, getShowcaseGeneral } from './actions';
import { AppDispatch } from 'redux/typings';

import { ShowcaseContent } from './typings.d';
import useActionStatus from 'hooks/useActionStatus';
import { newArtifactsFlowByDates } from 'utils/date';
import LoadingScreenSimple from 'modules/loading-screen-simple';


export type ValidImageKeys =
  'showcase-whoami'
  | 'showcase-choose-todo-something-hard'
  | 'showcase-declaration'
  | 'showcase-entrepreneurship'
  | 'showcase-parenting-1'
  | 'showcase-parenting-2';

export const IMAGES_DEFAULT: Record<ValidImageKeys, string> = {
  'showcase-whoami': showcaseWhoami,
  'showcase-choose-todo-something-hard': showcaseChooseTodoSomethingHard,
  'showcase-declaration': showcaseDeclaration,
  'showcase-entrepreneurship': showcaseEntrepreneurship,
  'showcase-parenting-1': showcaseParenting1,
  'showcase-parenting-2': showcaseParenting2,
};

const Showcase = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();

  const [isLoading] = useActionStatus(getShowcaseGeneral);
  const [isLoadingDate, wasFullfilledDate] = useActionStatus(getShowcaseCreationDate);

  const showcaseCreatedAt = useSelector(selectShowcaseCreatedAt);
  const isDeprecatedShowcase = useMemo(() => !newArtifactsFlowByDates(showcaseCreatedAt as unknown as Date), [showcaseCreatedAt]);

  const backgroundUrl = useSelector(selectBackgroundUrl);
  const videoUrl = useSelector(selectVideoUrl);

  const backgroundUrlImg = useMemo(() =>
    IMAGES_DEFAULT[backgroundUrl as ValidImageKeys] ||
    backgroundUrl
    ,
    [backgroundUrl]
  )

  useEffect(() => {
    if (id && id !== '' && !wasFullfilledDate) {
      dispatch(getShowcaseCreationDate({ showcaseId: id }));
    }
  }, [dispatch, id, wasFullfilledDate]);


  useEffect(() => {
    if (id && id !== '' && wasFullfilledDate) {
      dispatch(getShowcaseGeneral({ showcaseId: id, typeContent: ShowcaseContent.GENERAL }));
    }
  }, [dispatch, id, wasFullfilledDate, isDeprecatedShowcase]);

  return (
    <Suspense fallback={LoadingScreenSimple} >
      {(isLoading || isLoadingDate) ?
        <LoadingScreenSimple /> :
        null}

      {isDeprecatedShowcase ?
        <Switch>
          <Route exact path={SHOWCASE + SHOWCASE_PARAMS} component={ShowcaseHome} />
        </Switch>
        :
        <Route
          exact
          path={SHOWCASE + SHOWCASE_PARAMS}
          component={() => <ShowCaseResume image={backgroundUrlImg} />}
        />
      }
      <Route
        exact
        path={SHOWCASE + SHOWCASE_PARAMS + SHOWCASE_RESUME}
        component={() => <ShowCaseResume image={backgroundUrlImg} />}
      />
      <Route
        exact
        path={SHOWCASE + SHOWCASE_PARAMS + SHOWCASE_RESUME_VIDEO}
        component={() => <ShowCaseResume video={videoUrl} />}
      />
      <Route
        exact
        path={SHOWCASE + SHOWCASE_PARAMS + SHOWCASE_OVERVIEW}
        component={() => <ShowcaseOverview image={backgroundUrlImg} />}
      />
      <Route
        exact
        path={SHOWCASE + SHOWCASE_PARAMS + SHOWCASE_CONTACT}
        component={ShowcaseContact}
      />
    </Suspense>
  )
};

export default Showcase;
