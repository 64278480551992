import styled from '@emotion/styled';

import PathHorizontal from '../../../assets/images/PathHorizontal.webp';
import Oval from '../../../assets/images/Oval.webp';
import { ScrollableArea } from 'components/components';

type ShowCaseTimelineCardProps = {
  placeInScreen: string;
};

type ShowcaseTimelineButtonProps = {
  isSelectedGrid: boolean;
};

export const ShowCaseButtonsContainer = styled.div`
  margin-right: 3rem;
  margin-top: 1rem;
`;

export const ShowcaseCardImage = styled.div`
  height: 12.77rem;
  width: 12.77rem;
  align-self: center;
  margin-bottom: 2rem;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    align-self: flex-start;
    margin-right: 2rem;
    margin-bottom: 0;
  }
`;

export const ShowcaseCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    align-items: flex-start;
  }
`;

export const ShowCaseCardSubtitle = styled.p`
  text-align: left;
  color: ${(props) => props?.theme?.colors.grey[100]};
`;

export const ShowCaseCardTitle = styled.p`
  ${(props) => `
    color: ${props?.theme?.colors.blue2[100]};
    font-size: ${props?.theme?.typography.large24};
    font-weight: ${props?.theme?.typography.semiBold};
  `}
`;

export const ShowcaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: hidden;
`;

export const ShowCaseImages = styled.div`
  border-radius: 10px;
  height: 15.28rem;
  object-fit: cover;
  width: 15.28rem;
`;

export const ShowcaseImagesButton = styled.button`
  background-color: transparent;
  border: none;
  height: 15.28rem;
  margin-bottom: 1.7rem;
  margin-left: 0.85rem;
  margin-right: 0.85rem;
  width: 15.28rem;
`;

export const ShowCaseImagesContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    z-index: 13;
    margin-top: 2rem;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    @media (min-width: ${(props) => props?.theme?.breakpoints?.tablet}px) {
      margin-top: -3rem;
    }
  `;

export const ShowcaseItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ShowcaseImageAndTitle = styled.div`
  position: relative;
`;

export const ShowcaseTimelineButton = styled.button<
  ShowcaseTimelineButtonProps
>`
  ${(props) => `
  background-color: ${props.isSelectedGrid
      ? props?.theme?.colors.blue2[100]
      : props?.theme?.colors.white[100]
    };
  border: none;
  height: 2.78rem;
  padding: 0px;
  width: 2.78rem;
  margin: 0 2px;
  border-radius: 6px;
   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
      transform: translateY(-2px);
    }
  `}
`;

export const ShowcaseTextAndButtons = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  z-index: 13;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.mobile}px) {
    bottom: 2rem;
  }
  @media (min-width: ${(props) => props?.theme?.breakpoints?.tablet}px) {
    bottom: 5rem;
  }
`;

export const ShowcaseOverviewTitle = styled.div`
  width: 100%;
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large60};
  font-weight: ${(props) => props?.theme?.typography.bold};
  text-align: center;
`;

export const ShowcaseTimelineCard = styled.div<ShowCaseTimelineCardProps>`
  align-self: center;
  background-color: ${(props) => props?.theme?.colors.white[100]};
  border-radius: 8px;
  box-shadow: 0px 2px 63px 0px rgba(26, 26, 26, 0.08);
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  padding: 1.72rem;
  position: relative;
  width: 80%;
  z-index: 10;
  cursor:pointer;
  transition: filter 0.5s ease-in-out;

  &:hover {
    filter: drop-shadow(0 0 0px rgba(244, 191, 93, 0.7)) 
            drop-shadow(0 0 5px rgba(244, 191, 93, 0.5)) 
            drop-shadow(0 0 15px rgba(244, 191, 93, 0.3));
  }

  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    width: 60%;
    align-self: ${(props) => props.placeInScreen};
    flex-direction: row;
  }
  @media (min-width: ${(props) => props?.theme?.breakpoints?.hd}px) {
    width: 40%;
    margin-bottom: 0;
    &::after {
      content: '';
      ${(props) =>
    props.placeInScreen === 'flex-start' ? 'width:25%;' : 'width:25%;'}
      background: url(${PathHorizontal});
      ${(props) =>
    props.placeInScreen === 'flex-start'
      ? 'right: -25%;'
      : 'left:calc(-25% - 5px);'}
      height: 3px;
      position: absolute;
      top: 50%;
    }
    &::before {
      content: url(${Oval});
      height: 5px;
      position: absolute;
      top: 47%;
      ${(props) =>
    props.placeInScreen === 'flex-start'
      ? 'right: -26.5%;'
      : 'left:-26.5%;'}
    }
  }
`;

export const ShowCaseTimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-left: calc(env(safe-area-inset-left) + 3%);
  padding-right: calc(env(safe-area-inset-left) + 3%);
  position: relative;
  width: 100%;
  z-index: 13;
  margin-top: 2rem;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.tablet}px) {
    margin-top: -3rem;
  }
`;

export const ShowCaseTopImage = styled.img`
  height: 27rem;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  z-index: 10;
`;

export const ShowCaseTopImageOpacity = styled.div`
  background-color: black;
  height: 27rem;
  position: absolute;
  top: 0;
  opacity: 0.5;
  width: 100%;
  z-index: 12;
`;

export const ShowcasePictureModalPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  max-height: 80vh;
  z-index: 16;
  width: 95%;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    width: 80%;
  }
`;

export const ShowcasePictureModalContent = styled(ScrollableArea)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 1rem;
  width: 90%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

type ShowcaseModalBackgroundProps = {
  visible: boolean;
};

export const ShowcaseModalBackground = styled.div<ShowcaseModalBackgroundProps>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 15;
`;

export const ShowcaseModalHeader = styled.div`
  ${(props) => `
    color: ${props?.theme?.colors.grey[100]};
    font-size: ${props?.theme?.typography.large41};
    font-weight:  ${props?.theme?.typography.semiBold};
    text-align: center;
  `}
`;

export const ShowcaseModalPictureContent = styled.div`
  ${(props) => `
  display: flex;
  flex-direction: row;
  font-weight:  ${props?.theme?.typography.semiBold};
  justify-content: space-around;
  text-align: center;
  padding: 2rem;
`}
`;

export const ShowcasePictureCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
`;

export const ShowcasePictureCardName = styled.p`
  ${(props) => `
    font-size: ${props?.theme?.typography.large24};
    font-weight:  ${props?.theme?.typography.bold};
    margin-bottom: 0px;
  `}
`;

export const ShowCasePictureImage = styled.img`
  border-radius: 10px;
  height: 16.6rem;
  margin: 1.6rem 0;
  object-fit: cover;
  width: 16.6rem;
`;

export const ShowcasePictureDescription = styled.p`
  ${(props) => `
    font-size: 1rem;
    width: 16.6rem;
  `}
`;

export const ShowcaseModalHeaderVideo = styled(ShowcaseModalHeader)`
  ${(props) => `
  color: ${props?.theme?.colors.blue1[100]};
  margin-bottom: 2rem;
`}
`;

export const ShowcaseModalCloseButton = styled.button`
  align-self: flex-end;
  background-color: transparent;
  border: none;
  margin: 1rem 1rem 0 0;
  padding: 0;
`;

export const ShowCaseSeeMoreText = styled.p`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large25};
  margin-bottom: 2rem;
`;
export const ShowCaseHomeIcon = styled.img`
  width: 50%;
  height: auto;
`;