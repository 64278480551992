import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  UserProfileSection,
  EditButton,
  UserAvatarContainer,
  LoadingOverlay,
} from '../components';
import EditIcon from './components/EditIcon';
import LoadingSpinner from 'components/loading-spinner';
import { AppDispatch, RootState } from 'redux/typings';
import {
  selectDisplayName,
  selectEmail,
  selectProfilePictureUrl,
} from 'modules/auth/selectors';
import * as StatusSelectors from 'modules/status/selectors';
import { updateProfilePicture } from 'modules/auth/actions';
import { getFileObject } from 'helpers/FileHelpers';
import Avatar from 'components/avatar';
import UserNameSection from './components/UserNameSection';

const UserProfile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const email = useSelector(selectEmail);
  const displayName = useSelector(selectDisplayName);
  const profilePicture = useSelector(selectProfilePictureUrl);
  const isUploadingImage = useSelector((state: RootState) =>
    StatusSelectors.isPendingSelector(state, updateProfilePicture.typePrefix),
  );
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = useCallback(
    (evt) => {
      const files = evt.target.files;
      if (!files || files.length === 0) {
        return;
      }
      const file = files[0];
      if (!file.type.startsWith('image/')) {
        alert('Please provide an image file');
        return;
      }
      const image = getFileObject({
        name: file.name,
        type: file.type,
        uri: URL.createObjectURL(file),
      });
      if (image) {
        dispatch(updateProfilePicture(image));
      }
    },
    [dispatch],
  );

  const openImageUpload = useCallback(() => {
    uploadInputRef.current?.click();
  }, []);

  return (
    <>
      <UserProfileSection>
        <UserAvatarContainer>
          <Avatar
            displayName={displayName ?? ''}
            uri={profilePicture}
            size={155}
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={uploadInputRef}
            hidden
          />
          {isUploadingImage ? (
            <LoadingOverlay>
              <LoadingSpinner size={50} />
            </LoadingOverlay>
          ) : (
            <EditButton onClick={openImageUpload}>
              <EditIcon />
            </EditButton>
          )}
        </UserAvatarContainer>
      </UserProfileSection>

      <UserNameSection displayName={displayName ?? ''} email={email} />
    </>
  );
};

export default UserProfile;
