export type SetFieldValueType = (
  field: string,
  value: any,
  shouldValidate?: boolean | undefined,
) => void;

export type InitialValuesType = {
  name: string;
  callToAction: string;
  applets: string[];
  featuredApplets: string[];
  headlineApplet: string;
  backgroundApplet: string;
  videoApplet: string;
};
export type InitialValuesGeneralArtifactType = {
  name: string;
  callToAction: string;
  featuredApplets: string[];
  backgroundApplet: string;
  applets: string[];
  headlineApplet: string;
  videoApplet: string;
};

export type StepProps = {
  values: InitialValuesType;
  setFieldValue: SetFieldValueType;
};

export type StepPropsSplit = {
  values: Partial<InitialValuesGeneralArtifactType>;
  setFieldValue: SetFieldValueType;
};

export type VideoUrl = { videoUrl?: string }

export enum ShowcaseContent {
  GENERAL = '1',
  ARTIFACTS = '2'
}