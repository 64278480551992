import { useCallback } from 'react';

import { QuestApplet } from 'models/QuestApplet';
import { SetFieldValueType } from 'modules/profile/portfolio/published-showcases/publish-showcase-modal/typings';

const useHandleAppletSelect = (
  setFieldValue: SetFieldValueType,
  selectedApplets: string[],
  fieldName: string,
) => {
  const handleAppletSelect = useCallback(
    (applet: QuestApplet) => {
      let newValue: string[] = [];
      const { id, appletType } = applet;

      if (fieldName === 'featuredApplets') {
        const ids = applet?.applets?.map((app) => app.id) || [];
        newValue = [...ids, id]
        setFieldValue(fieldName, newValue);
      } else {
        if (appletType === 'group-applet') {
          const ids = applet?.applets?.map((app) => app.id) || [];
          if (selectedApplets.some((selApp) => ids.includes(selApp))) {
            newValue = selectedApplets.filter((selApp) => !ids.includes(selApp));
          } else {
            newValue = [...selectedApplets, ...ids];
          }
        } else {
          const appletIndex = selectedApplets.findIndex((app) => app === id);
          if (appletIndex === -1) {
            newValue = [...selectedApplets, id];
          } else {
            newValue = [...selectedApplets];
            newValue.splice(appletIndex, 1);
          }
        }
      }

      setFieldValue(fieldName, newValue);
    },
    [setFieldValue, fieldName, selectedApplets],
  );

  return handleAppletSelect;
};

export default useHandleAppletSelect;
