import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { useTheme } from '@emotion/react';

import localizedStrings from 'localization';
import {
  CenterLoaderShowcase,
  PublishShowcaseContent,
  PublishShowcaseTitle,
  ShowcaseForm,
  ShowcaseFormButtons,
  ShowcaseNextButton,
} from 'modules/profile/components';
import Modal from 'components/modal';
import GeneralInfo from './GeneralInfo';
import SelectArtifacts from './SelectArtifacts';
import SelectFeaturedArtifacts from './SelectFeaturedArtifacts';
import SelectBackgroundImage from './SelectBackgroundImage';
import { UpdateShowcaseBodyArgs } from 'modules/profile/typings';
import { selectQuestPortfolioApplets } from 'modules/applets/selectors';
import { buildQuestAppletsList } from 'helpers/AppletHelpers';
import useActionStatus from 'hooks/useActionStatus';
import { updateShowcase } from 'modules/profile/actions';
import { AppDispatch } from 'redux/typings';
import { clearShowcase, getShowcaseGeneral, getShowcaseArtifact } from 'modules/showcase/actions';
import { selectShowcase } from 'modules/showcase/selectors';
import LoadingSpinner from 'components/loading-spinner';
import { CenterLoader } from 'components/loading-spinner/components';
import { InitialValuesGeneralArtifactType, ShowcaseContent } from './typings.d';
import { IMAGES_DEFAULT, ValidImageKeys } from 'modules/showcase';
import { toast } from 'react-toastify';

type PublishedShowcaseProps = {
  open: boolean;
  closeModal: () => void;
  showcaseId: string;
};

const EditShowcaseModal = ({ open, closeModal, showcaseId }: PublishedShowcaseProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formikRef = useRef<FormikProps<Partial<InitialValuesGeneralArtifactType>>>(null);
  const formikArtifactsRef = useRef<FormikProps<Partial<InitialValuesGeneralArtifactType>>>(null);

  const questPortfolioApplets = useSelector(selectQuestPortfolioApplets);

  const [isPending, wasFulfilled] = useActionStatus(updateShowcase);
  const [isLoadingGeneral, wasFulfilledGeneral] = useActionStatus(getShowcaseGeneral);
  const [isLoadingArtifacts] = useActionStatus(getShowcaseArtifact);
  const showcase = useSelector(selectShowcase);
  const backgroundUrlImg = useMemo(() =>
    IMAGES_DEFAULT[showcase?.backgroundUrl as ValidImageKeys] ||
    showcase?.backgroundUrl
    ,
    [showcase?.backgroundUrl]
  )

  const groupedApplets = useMemo(
    () => buildQuestAppletsList(questPortfolioApplets),
    [questPortfolioApplets],
  );

  const handleModalClose = useCallback(() => {
    formikRef.current?.resetForm();
    closeModal();
  }, [closeModal]);

  const submitForm = useCallback(() => {
    const values = formikRef.current?.values;
    const valuesArtifacts = formikArtifactsRef.current?.values;
    const showcase: Partial<UpdateShowcaseBodyArgs> = {};

    if (values) {
      if (values.name !== undefined && values.name.length) {
        showcase.title = values.name;
      } else {
        toast.error(localizedStrings.fieldsRequired)
        return;
      }

      if (values.callToAction !== undefined && values.callToAction.length) {
        showcase.callToAction = values.callToAction;
      } else {
        toast.error(localizedStrings.fieldsRequired)
        return;
      }



      if (values.backgroundApplet !== undefined) {
        const backgroundUrl = questPortfolioApplets.find((applet) => applet.id === values.backgroundApplet)?.fileUrl;
        if (backgroundUrl) {
          showcase.backgroundUrl = backgroundUrl;
        }
      }

    } else {
      toast.error(localizedStrings.fieldsRequired)
      return;
    }

    if (valuesArtifacts) {
      if (valuesArtifacts.applets !== undefined && valuesArtifacts.applets.length > 2) {
        showcase.appletIds = valuesArtifacts.applets;
      } else {
        toast.error(localizedStrings.fieldsRequired)
        return;
      }

      if (valuesArtifacts.featuredApplets !== undefined) {
        showcase.featuredAppletIds = valuesArtifacts.featuredApplets;
      } else {
        toast.error(localizedStrings.fieldsRequired)
        return;
      }

      if (valuesArtifacts.headlineApplet !== undefined) {
        const headlineApplet = groupedApplets.find((group) => group.id === valuesArtifacts.headlineApplet) as any;
        if (headlineApplet) {
          showcase.headlineAppletIds =
            headlineApplet.appletType === 'group-applet'
              ? headlineApplet.applets?.map((applet: any) => applet.id) ?? []
              : [headlineApplet.id];
        }
      }
      if (valuesArtifacts.videoApplet !== undefined) {
        const videoUrl = questPortfolioApplets.find((applet) => applet.id === valuesArtifacts.videoApplet)?.fileUrl;
        if (videoUrl) {
          showcase.videoUrl = videoUrl;
        }
      }
    } else {
      return;
    }

    dispatch(updateShowcase({ showcaseId, data: showcase }));
    setHasSubmitted(true);
  },
    [
      questPortfolioApplets,
      groupedApplets,
      showcaseId,
      dispatch,
      setHasSubmitted]
  );


  useEffect(() => {
    if (hasSubmitted && wasFulfilled) {
      setHasSubmitted(false);
      handleModalClose();
    }
  }, [hasSubmitted, handleModalClose, wasFulfilled, theme?.colors.success]);

  useEffect(() => {
    if (showcaseId && showcaseId !== '') {
      dispatch(getShowcaseGeneral({ showcaseId, typeContent: ShowcaseContent.GENERAL }));
      dispatch(getShowcaseArtifact({ showcaseId, typeContent: ShowcaseContent.ARTIFACTS }));
    }
    return () => {
      dispatch(clearShowcase())
    }
  }, [dispatch, showcaseId]);

  if (isPending) {
    return <CenterLoader>
      <LoadingSpinner size={50} color={theme.colors.blue1[100]} />
    </CenterLoader>
  }

  return (
    <Modal open={open} closeModal={handleModalClose} width="910px">
      <PublishShowcaseContent>
        <PublishShowcaseTitle>
          {localizedStrings.editAShowcase}
        </PublishShowcaseTitle>

        {
          isLoadingGeneral ?
            <CenterLoaderShowcase>
              <LoadingSpinner size={50} color={theme.colors.blue1[100]} />
            </CenterLoaderShowcase>
            :
            <Formik
              initialValues={{
                name: showcase?.title || '',
                callToAction: showcase?.callToAction || '',
                featuredApplets: (showcase?.featuredApplets && showcase?.featuredApplets.map(el => el.id) as any) || [],
                backgroundApplet: ''
              }}
              onSubmit={() => { }}
              innerRef={formikRef}>
              {({ values, setFieldValue }) => (
                <ShowcaseForm>
                  <GeneralInfo {...{ values, setFieldValue }} />
                  <SelectBackgroundImage {...{ values, setFieldValue, backgroundUrl: backgroundUrlImg }} />
                </ShowcaseForm>
              )}
            </Formik>
        }

        {
          wasFulfilledGeneral &&
          <>
            {
              isLoadingArtifacts ?
                <CenterLoaderShowcase>
                  <LoadingSpinner size={50} color={theme.colors.blue1[80]} />
                </CenterLoaderShowcase>
                :
                <Formik
                  initialValues={{
                    applets: (showcase?.applets && showcase?.applets.map(el => el.id) as any) || [],
                    featuredApplets: (showcase?.featuredApplets && showcase?.featuredApplets.map(el => el.id) as any) || [],
                    headlineApplet: '',
                    videoApplet: '',
                  }}
                  onSubmit={() => { }}
                  innerRef={formikArtifactsRef}>
                  {({ values, setFieldValue }) => (
                    <ShowcaseForm>
                      <SelectFeaturedArtifacts {...{ values, setFieldValue, }} />
                      <SelectArtifacts {...{ values, setFieldValue }} />
                    </ShowcaseForm>
                  )}
                </Formik>
            }
          </>
        }

        <ShowcaseFormButtons>
          <ShowcaseNextButton type="submit" onClick={submitForm}>
            {localizedStrings.save}
          </ShowcaseNextButton>
        </ShowcaseFormButtons>
      </PublishShowcaseContent>
    </Modal>
  );
};

export default EditShowcaseModal;
