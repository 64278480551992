import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import localizedStrings from 'localization';
import {
  PublishShowcaseAppletsContainer,
  PublishShowcaseDescription,
} from 'modules/profile/components';
import { selectQuestPortfolioApplets } from 'modules/applets/selectors';
import AppletPreview from 'modules/profile/portfolio/quest-applets/AppletPreview';
import { StepPropsSplit } from './typings';
import { buildQuestAppletsList } from 'helpers/AppletHelpers';
import useIsAppletSelected from 'hooks/useIsAppletSelected';
import { ShowMoreShowcaseItem } from '../ShowMoreShowcaseItem';
import useHandleAppletSelect from 'hooks/useHandleAppletSelect';
import { FieldErrorMessage } from 'components/input-field/components';

const SelectFeaturedArtifacts = ({
  values,
  setFieldValue,
}: StepPropsSplit) => {
  const [showMore, setShowMore] = useState(false);
  const questPortfolioApplets = useSelector(selectQuestPortfolioApplets);

  const handleShowMore = () => setShowMore(!showMore);

  const applets = useMemo(
    () =>
      buildQuestAppletsList(questPortfolioApplets),
    [questPortfolioApplets],
  );

  const titleCountSelected = localizedStrings.featuredArtifactsIncluded;

  const handleAppletSelect = useHandleAppletSelect(
    setFieldValue,
    values?.featuredApplets || [],
    'featuredApplets',
  );

  const isSelected = useIsAppletSelected(values?.featuredApplets || []);

  return !showMore ?
    <ShowMoreShowcaseItem
      title={titleCountSelected}
      action={handleShowMore}
      actionState={showMore}
      labelShow={localizedStrings.change} />
    :
    (
      <>
        <ShowMoreShowcaseItem title={titleCountSelected} />
        <PublishShowcaseDescription>
          {localizedStrings.selectShowcaseFeatured}
          {!values?.featuredApplets?.length ? (
            <FieldErrorMessage>
              {localizedStrings.showcaseFeaturedArtifactMinimumError}
            </FieldErrorMessage>
          ) : null}
        </PublishShowcaseDescription>
        <PublishShowcaseAppletsContainer>
          {applets.map((applet) => (
            <AppletPreview
              key={applet.id}
              applet={applet}
              onAppletClick={handleAppletSelect}
              showTitle={true}
              height="168px"
              width="178px"
              selected={isSelected(applet)}
            />
          ))}
        </PublishShowcaseAppletsContainer>
      </>
    );
};

export default SelectFeaturedArtifacts;
