import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ArrowNext from '../../../assets/icons/ArrowNext.webp';
import ArrowBack from '../../../assets/icons/ArrowBack.webp';
import ArrowBackDisabled from '../../../assets/icons/ArrowBackDisabled.webp';
import ShowcaseBackground from '../../../assets/images/ShowcaseBackground.webp';
import {
  ShowCaseArrow,
  ShowCaseArrowImage,
  ShowCaseBackgorundImage,
  ShowcaseContainer,
  ShowcaseItemsContainer,
  ShowcaseTextContainer,
} from './ShowcaseHomeComponents';
import { Container } from '../../../common/CommonComponents';
import { selectBackgroundUrl } from '../selectors';
import ShowcaseHomeItem from './ShowcaseHomeItem';
import { ShowCaseVideo } from '../resume/ShowcaseResumeComponents';
import { HeadlineApplet } from '../typings';

const ShowcaseHeadline = ({ headlineApplets }: { headlineApplets: HeadlineApplet[] }) => {
  const [page, setPage] = useState(0);
  const [opacity, setOpacity] = useState(1);

  const backgroundUrl = useSelector(selectBackgroundUrl);

  const isVideoApplet = useMemo(
    () => headlineApplets.length === 1 && headlineApplets[0].videoUrl,
    [headlineApplets],
  );

  const calculateOpacity = useMemo(() => {
    return (headlineApplets.length - 1 - page) / headlineApplets.length;
  }, [page, headlineApplets.length]);

  const renderStepsText = useMemo(() => {
    return (
      <ShowcaseTextContainer>
        {headlineApplets && headlineApplets.length > 0 && (
          <>
            <ShowcaseHomeItem applet={headlineApplets[page]} />
            {page + 1}/{headlineApplets.length}
          </>
        )}
      </ShowcaseTextContainer>
    );
  }, [page, headlineApplets]);

  const goBack = useCallback(() => {
    if (page !== 0) {
      page === 1 ? setOpacity(1) : setOpacity(calculateOpacity);
      setPage(page - 1);
    }
  }, [page, calculateOpacity]);

  const goNext = useCallback(() => {
    if (!(page + 1 === headlineApplets.length)) {
      setPage(page + 1);
      setOpacity(calculateOpacity);
    }
  }, [page, calculateOpacity, headlineApplets]);

  return (
    <Container>
      <ShowcaseContainer>
        <ShowCaseBackgorundImage slideOpacity={1} src={backgroundUrl} />
        <ShowCaseBackgorundImage
          slideOpacity={isVideoApplet ? 0 : opacity}
          src={ShowcaseBackground}
        />
        <ShowcaseItemsContainer>
          <ShowCaseArrow onClick={goBack}>
            <ShowCaseArrowImage
              src={page === 0 ? ArrowBackDisabled : ArrowBack}
              alt="back"
            />
          </ShowCaseArrow>
          {isVideoApplet ? (
            <ShowCaseVideo
              loop
              autoPlay
              controls
              src={headlineApplets[0].videoUrl}
            />
          ) : (
            renderStepsText
          )}
          <ShowCaseArrow onClick={goNext}>
            <ShowCaseArrowImage src={ArrowNext} alt="next" />
          </ShowCaseArrow>
        </ShowcaseItemsContainer>
      </ShowcaseContainer>
    </Container>
  );
};

export default ShowcaseHeadline;
