import { baseService } from 'modules/baseService';
import HttpClient from 'services/HttpClient';
import { Showcase, ShowcaseEmail } from './typings';

export const getShowcaseCreationDate = baseService<{ showcaseId: string }, Date>(({ showcaseId }) =>
  HttpClient.get(`/showcases/created/${showcaseId}`),
);

export const getShowcase = baseService<{ showcaseId: string, typeContent: string }, Partial<Showcase>>(({ showcaseId, typeContent }) =>
  HttpClient.get(`/showcases/${showcaseId}/content/${typeContent}`),
);

export const sendEmail = baseService<ShowcaseEmail, void>(
  ({ showcaseId, ...body }) =>
    HttpClient.post(`/showcases/${showcaseId}/contact`, body),
);
