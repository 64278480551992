import styled from '@emotion/styled';
import { Image } from 'react-bootstrap';
import { Form } from 'formik';
import ButtonPrimary from 'components/button-primary';
import background from 'assets/images/MapBackground.webp';

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

export const BgContainer = styled.div`
  ${(props) => `
    font-family: ${props?.theme?.typography.normal};
    width: 100vw;
    height: 100vh;
    background-image: url(${background});
    background-position: center;
    background-size: cover;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

export const TxtMessage = styled.div`
  ${(props) => `
    color: ${props?.theme?.colors.white[100]};
    font-size: ${props?.theme?.typography.large32};
    margin: 20px;
  `}
`;

type RatioContainerProps = {
  ratio: number;
};

export const RatioContainer = styled.div<RatioContainerProps>`
  height: 0;
  overflow: hidden;
  padding-top: calc(${(props) => (props.ratio ? props.ratio : 1 / 1)} * 100%);
  background: transparent;
  position: relative;
`;

export const ImageRatio = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const UnstyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InputContainer = styled.div`
  width: 50%;
`;

export const AddOptionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const UserOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0rem;
`;

export const UserOptionIconsContainer = styled.div`
  display: flex;
`;

export const IconContainer = styled.div`
  cursor: pointer;
`;

export const AddOptionButton = styled(ButtonPrimary)`
  width: 50%;
  margin-top: 1rem;
`;

export const SaveOptionsButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: 1rem;
`;

export const SaveOptionsButton = styled(ButtonPrimary)`
  width: 50%;
  margin-bottom: 1rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${(props) => props?.theme?.typography.large32};
`;

export const SaveButton = styled(ButtonPrimary)`
  width: 50%;
`;
