import { createReducer } from '@reduxjs/toolkit';
import { ShowcaseState } from './typings';
import { clearShowcase, getShowcaseGeneral, getShowcaseArtifact, sendEmail, getShowcaseCreationDate } from './actions';
import { toast } from 'react-toastify';

const initialState: ShowcaseState = {};

export default createReducer<ShowcaseState>(initialState, (builder) =>
  builder
    .addCase(getShowcaseCreationDate.fulfilled, (state, action) => ({
      showcase: {
        ...state.showcase,
        createdAt: action.payload
      },
    }))
    .addCase(getShowcaseGeneral.fulfilled, (state, action) => ({
      showcase: {
        ...state.showcase,
        ...action.payload
      },
    }))
    .addCase(getShowcaseArtifact.fulfilled, (state, action) => ({
      showcase: {
        ...state.showcase,
        ...action.payload
      },
    }))
    .addCase(clearShowcase, () => ({
      showcase: undefined
    }))
    .addCase(sendEmail.rejected, (state, _) => {
      toast.error(
        'There was an error sending your email. Please try again later',
      );
      return state;
    }),
);
