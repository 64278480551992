import { Document } from '@contentful/rich-text-types';
import {
  AppletType,
  FileUploadData,
  MadlibAnswer,
  QuizQuestion,
} from 'models/Applet';
import { IQuiz, IRankOptions } from 'models/contentful';
import { RankOption } from 'models/RankOption';

export interface Showcase {
  id: string;
  userDisplayName: string;
  title: string;
  callToAction: string;
  backgroundUrl: string;
  videoUrl: string;
  headlineApplets: HeadlineApplet[];
  featuredApplets: ShowcaseApplet[];
  applets: ShowcaseApplet[];
  createdAt: Date;
}

export interface HeadlineApplet {
  title?: Document;
  body?: string;
  videoUrl?: string;
}

export interface ShowcaseApplet {
  id: string;
  contentfulId: string;
  appletType: AppletType | 'group-applet';
  description?: string;
  thumbnailUrl?: string;
  groupId?: string;
  groupOrder?: number;
  title: string;
  body?: string;
  fileType?: string;
  fileUrl?: string;
  richText?: string;
  text?: string;
  quiz?: IQuiz;
  quizQuestions?: QuizQuestion[];
  files?: FileUploadData[];
  options?: RankOption[];
  optionsContentful?: IRankOptions[];
  madlibAnswers?: MadlibAnswer[];
  madlibSentence?: Document;
  createdAt: string;
  updatedAt: string;
  headlineApplet?: HeadlineApplet;
}

export interface ShowcaseState {
  showcase?: Partial<Showcase>;
}

export type ShowcaseEmail = {
  showcaseId: string;
  firstName: string;
  lastName: string;
  email: string;
  message: string;
};

export enum ShowcaseContent {
  GENERAL = '1',
  ARTIFACTS = '2'
}